//
@media all and (min-width: $breakpoint+1) {
  .sp,
  .-sp {
    display: none !important;
  }
}
@media all and (max-width: $breakpoint) {
  .pc,
  .-pc {
    display: none !important;
  }
}

@mixin pc {
  @media all and (min-width: $breakpoint+1) {
    @content;
  }
}

@mixin pcin {
  @media all and (min-width: $breakpoint+1) and (max-width: 1440px) {
    @content;
  }
}

@mixin pcOver {
  @media all and (min-width: 1441px) and (max-width: 1799px) {
    @content;
  }
}

@mixin pcMax {
  @media all and (min-width: 1800px) {
    @content;
  }
}

@mixin sp {
  @media all and (max-width: $breakpoint) {
    @content;
  }
}

$screenW: 1440;
@function pcpx($px) {
  @return calc($px / $screenW) * 100 + vw;
}
@function pcpxMax($px) {
  @return $px * calc(1800 / $screenW) + px;
}
@function sppx($px) {
  @return ($px / 750) * 100 + vw;
}

//browser riquid font
//@include fz_vw(11);
@function get_vw($size, $viewport: 375) {
  $rate: 100 / $viewport;
  @return $rate * $size * 1vw;
}

@mixin fz_vw($font_size: 10) {
  font-size: $font_size * 1px;
  font-size: get_vw($font_size);
}

@mixin mi-100vh {
  @include pc {
    height: 100vh;
  }
  @include sp {
    min-height: 100vh;

    @supports (-webkit-touch-callout: none) {
      min-height: -webkit-fill-available;
    }
  }
}
@mixin mi-imghvr {
  @include pc {
    transition: opacity 0.3s;
    &:hover {
      opacity: 0.5;
    }
  }
}
