#index {
  // &.-fvPast {
  //   header {
  //     background: url(../images/common/bg.png) top left;
  //     &:before {
  //       opacity: 1;
  //     }
  //   }
  // }
  .devScrl {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background: #333;
  }
  .pageWrp {
    overflow: hidden;
    width: 100%;
  }
  .pageBg {
    position: fixed;
    top: 340px;
    left: 0;
    width: 100%;
    text-align: center;
    img {
      width: 100%;
      max-width: 1300px;
    }
    @include sp {
      top: 235px;
      img {
        width: 190%;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
      }
    }
  }
  .kvBlock {
    position: relative;
    min-height: 100vh; /* Fallback */
    min-height: calc(var(--vh, 1vh) * 100);

    &__img,
    &__txt {
      position: absolute;
      line-height: 0;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      img {
        width: 100%;
      }
      @include sp {
        // img{
        //   width: sppx(546);
        // }
      }
    }
    &__model {
      position: absolute;
      line-height: 0;
      left: 50%;
      top: 0%;
      transform: translateX(-50%);
      width: 100%;
      @include pc {
        max-width: 1440px;
        margin-top: -40px;
      }
      img {
        position: relative;
        width: 100%;
      }
      @include pc {
        img {
          //transition: top .3s;
        }
      }
      @include sp {
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
    &__ttl {
      -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;

      font-weight: normal;
      font-weight: 500;
      line-height: 1;
      position: absolute;
      left: 35px;
      top: 95px;
      font-size: 85px;
      @include sp {
        font-size: calc(85px / 2);
        top: 48px;
        left: 10px;
      }
    }
    &__scrl {
      line-height: 1;
      text-decoration: none;
      position: absolute;
      color: #fff;
      font-weight: 500;
      right: 12px;
      bottom: 50px;
      font-size: 12px;

      &__dot {
        position: absolute;
        left: -11px;
        top: -20px;
        width: 6px;
        height: 6px;
        &:before {
          position: absolute;
          content: '';
          background: #fff;
          border-radius: 50%;
          width: 100%;
          height: 100%;
          -webkit-animation: dotmov 1.5s $ease infinite;
          animation: dotmov 1.5s $ease infinite;
        }
      }
      &__txt {
        transform: rotate(90deg);
        transform-origin: left top;
      }
      @include sp {
        left: auto;
        right: -20px;
        font-size: 12px;
        padding-left: 0px;
        bottom: 35px;
        // &:before{
        //   width: 5px;
        //   height: 5px;
        // }
        &__dot {
          left: -9px;
          width: 5px;
          height: 5px;
        }
        &__txt {
          display: inline-block;
        }
      }
    }
    @-webkit-keyframes dotmov {
      0% {
        transform: translateY(-150%);
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      70% {
        opacity: 0.9;
      }
      100% {
        transform: translateY(150%);
        opacity: 0;
      }
    }
    @keyframes dotmov {
      0% {
        transform: translateY(-150%);
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      80% {
        opacity: 0.9;
      }
      100% {
        transform: translateY(150%);
        opacity: 0;
      }
    }

    &__buyBtn {
      border-radius: 50px;
      font-weight: 700;
      text-decoration: none;
      color: #000;
      position: absolute;
      left: 30px;
      bottom: 30px;
      width: 278px;
      height: 70px;
      background: rgba(255, 255, 255, 1);
      display: flex;
      align-items: center;
      justify-content: center;
      letter-spacing: -0.2px;

      font-size: 18px;
      &__txt {
      }
      &__cart {
        margin-right: 15px;
        img {
          width: 15px;
          height: auto;
        }
      }
      &__arw {
        margin-left: 17px;
        img {
          width: 12px;
          height: auto;
        }
      }
      @include sp {
        left: 15px;
        bottom: 15px;
        width: 198px;
        height: 38px;
        padding-bottom: 2px;
        font-size: 12px;

        &__cart {
          margin-right: 8px;
          img {
            width: 10px;
          }
        }
        &__arw {
          margin-left: 11px;
          img {
            width: 10px;
          }
        }
      }
    }
  }

  // kv styles by hasegawa
  .kvBlock {
    position: relative;
  }

  .kvBlock__inner {
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateY(-50%);

    @media (min-aspect-ratio: 120/88) {
      height: 110%;
    }
    @media (min-aspect-ratio: 120/78) {
      height: 120%;
    }
  }

  .kvBlock__bgImage {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    // background-color: #ccc;
    top: 98px;
    bottom: 88px;
    left: 43px;
    right: 43px;
    border-radius: 60px;

    @include pc {
      border-radius: 150px;
      top: 120px;
      bottom: 80px;
      left: 170px;
      right: 170px;
    }
  }

  .kvBlockTitle {
    font-weight: 500;
    line-height: 0.978;
    position: absolute;
    font-size: #{85 / 750 * 100vw};
    top: 48px;
    left: 10px;
    transform-origin: top left;
    transform: rotate(90deg) translateY(-100%);
    z-index: 2;

    @include pc {
      left: 35px;
      top: 95px;
      font-size: #{90 / 1440 * 100vw} !important;
    }
  }

  .kvBlock[data-layout='landscape'] .kvBlockTitle {
    font-size: #{100 / 1440 * 100vw};
  }

  .kvBlockTitle__line {
    display: block;
    position: relative;
    clip-path: inset(-0.2em 0em);
  }

  .kvBlockTitle__lineInner {
    display: block;
    transition-property: transform;
    transition-duration: 0.8s;
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transform: translateY(140%);
  }

  .kvBlock__timerIndicator {
    position: absolute;
    right: 15px;
    top: 98px;
    width: 12px;
    height: 12px;
    z-index: 3;
    transform: rotate(-90deg);

    svg {
      fill: $color-white;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    @include pc {
      top: 96px;
      right: 40px;
      width: 16px;
      height: 16px;
    }
  }

  .kvBlockSceneIndicator {
    position: absolute;
    font-size: 11px;
    left: calc(100% - 15px + 0.04em);
    top: 120px;
    transform-origin: top left;
    transform: rotate(90deg);
    letter-spacing: 0.1em;
    line-height: 1;
    vertical-align: middle;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    font-weight: 500;
    overflow: hidden;

    @include pc {
      font-size: 12px;
      transform: translateX(-50%);
      left: 50%;
      top: auto;
      bottom: 36px;
    }
  }

  .kvBlockSceneIndicator__current,
  .kvBlockSceneIndicator__total {
    position: relative;
    height: 1em;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .kvBlockSceneIndicator__digit {
    width: 0.7em;
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .kvBlockModelName {
    color: #e60012;
    position: absolute;
    clip-path: inset(-0.2em 0em);
    font-size: 18px;
    font-weight: 500;
    line-height: 1;
    bottom: -0.5em;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;

    @include pc {
      font-size: 36px;
    }
  }

  .kvBlock[data-layout='landscape'] .kvBlockModelName {
    transform: translate(-50%, -50%) rotate(90deg);
    left: calc(100% + 0.08em);
    bottom: auto;
    top: 50%;
  }

  .kvBlockModelName__inner {
    display: block;
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transform: translateY(140%);
    transition-duration: 0.8s;
    transition-delay: 0.6s;
  }

  .kvBlock.is-playing {
    .kvBlockTitle__line:nth-child(1) .kvBlockTitle__lineInner {
      transition-delay: 0s;
    }
    .kvBlockTitle__line:nth-child(2) .kvBlockTitle__lineInner {
      transition-delay: 0.1s;
    }
    .kvBlockTitle__line:nth-child(3) .kvBlockTitle__lineInner {
      transition-delay: 0.2s;
    }

    .kvBlockTitle__lineInner {
      transform: none;
    }
  }
  .kvBlock:not(.is-swapping).is-playing .kvBlockModelName__inner {
    transform: none !important;
    transition-delay: 1s;
  }

  .kvBlock__photoCredit {
    color: $color-white;
    position: absolute;
    font-size: 8px;
    font-weight: 500;
    line-height: 1.25;
    bottom: 18px;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    background-color: rgba(0, 0, 0, 0.5);
    transition-property: opacity;
    transition-duration: 0.2s;
    transition-timing-function: linear;
    transition-delay: 0.4s;
    opacity: 0;

    @include pc {
      font-size: 12px;
      bottom: 36px;
    }
  }

  .kvBlock[data-layout='landscape'] .kvBlock__photoCredit {
    transform: none;
    left: auto;
    right: 26px;
    bottom: 20px;

    @include pc {
      right: 52px;
      bottom: 40px;
    }
  }
  .kvBlock:not(.is-swapping):not(.is-noThumb).is-playing .kvBlock__photoCredit {
    opacity: 1 !important;
    transition-delay: 1.4s;
    transition-duration: 0.2s;
  }

  .snsSide {
    color: $clr-grey;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 40px;
    &__ttl {
      -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
      width: 19px;
      font-size: 12px;
    }
    &__list {
      border-top: dotted #fff 1px;
      padding-left: 1px;
      margin-top: 15px;
      padding-top: 25px;
      li {
        line-height: 0;
        margin-bottom: 20px;
        a {
          display: block;
          @include mi-imghvr;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
      img {
        width: 20px;
      }
    }
    @include sp {
      display: none;
    }
  }
  //-------------------------------------------------------------

  #about {
    @include pc {
      margin-top: 75px;
    }
    @include sp {
      margin-top: 35px;
    }
  }

  //-------------------------------------------------------------
  .secAbout {
    position: relative;

    @include pc {
      margin-top: 125px;
    }
    @include sp {
      margin-top: 60px;
    }
    &__bg {
      //background: url(../images/index/community-bg.jpg) center center /cover no-repeat;
      position: absolute;
      top: -90px;
      left: 0;
      width: 100%;
      height: 100%;

      img {
        width: 100%;
      }
      @include sp {
        top: -5px;
        // .simpleParallax{
        //   height: 100%;
        // }
        // @media all and (max-width: 680px) {
        //   img{
        //     width: auto;
        //     height: 100%;
        //   }
        // }
      }
    }
    &__in {
      position: relative;
      @include sp {
        margin: 0 15px;
      }
    }
    &__ttl {
      position: relative;
      z-index: 1;
      @include mi-secTtl;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 65px;
      @include pc {
        //width:calc(100% - 180px);
        span {
          display: block;
          max-width: 1260px;
          margin: 0 auto;
          @include pcOver {
            max-width: pcpx(1260);
          }
          @include pcMax {
            max-width: pcpxMax(1260);
          }
        }
      }
      @include sp {
        padding: 0 15px;
        position: relative;
        margin-bottom: 25px;
        padding-left: 0;
      }
    }
    &__cntWrp {
      @include pc {
        padding: 0 30px;
      }
    }

    .aboutCnt {
      margin-left: auto;
      margin-right: auto;
      @include pc {
        max-width: 1380px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 80px;
        @include pcOver {
          max-width: pcpx(1380);
        }
        @include pcMax {
          max-width: pcpxMax(1380);
        }

        &:nth-child(1),
        &:nth-child(3) {
          flex-direction: row-reverse;
        }
        &__img {
          width: calc(50% - 15px);
          padding-top: 32.609%;
        }
        &__txtarea {
          width: calc(50% - 15px);
          &__in {
            width: 410px;
            margin: 0 auto;
            @include pcOver {
              width: pcpx(410);
            }
            @include pcMax {
              width: pcpxMax(410);
            }
          }
        }
      }

      @include sp {
        margin-bottom: 50px;
        &__img {
          width: 100%;
          padding-top: 66.67%;
        }
      }
      &__img {
        z-index: 1;
        line-height: 0;
        white-space: nowrap;
        position: relative;
        overflow: hidden;
        border-radius: 10px;
        &__in {
          position: absolute;
          left: 0;
          top: 0;
          -webkit-animation: imgmov 40s linear infinite;
          animation: imgmov 40s linear infinite;
          &.-item5 {
            -webkit-animation: imgmov5 50s linear infinite;
            animation: imgmov5 50s linear infinite;
          }
        }
        img {
          width: 100%;
        }
      }
      &__txtarea {
        &__num {
          color: $color-red;
          line-height: 1;
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 5px;
        }
        &__ttl {
          font-weight: 500;
          color: $color-red;
          line-height: calc(55 / 45);
          font-size: 45px;
          margin-bottom: 25px;
        }
        &__txt {
          line-height: calc(28 / 16);
          font-size: 16px;
          text-align: justify;
        }
        &__btn {
          display: flex;
          margin-top: 30px;

          a {
            display: inline-block;
            text-decoration: none;
            background: rgba(255, 255, 255, 0.2);
            color: #fff;
            line-height: 1;
            border-radius: 50px;
            padding: 20px 33px;
            font-size: 16px;
            margin-right: 20px;
            &:last-child {
              margin-right: 0;
            }
            &.-insta {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0;
              width: 56px;
              height: 56px;
              img {
                width: 22px;
              }
            }
          }
        }
        @include sp {
          padding: 20px 20px 0px;
          &__num {
            font-size: 12px;
            margin-bottom: 0px;
            font-weight: 500;
          }
          &__ttl {
            line-height: calc(55 / 45);
            font-size: 25px;
            margin-bottom: 8px;
          }
          &__txt {
            line-height: calc(38 / 26);
            font-size: 13px;
            &.jp {
              line-height: 1.6;
            }
          }
          &__btn {
            margin-top: 18px;

            a {
              padding: 13px 25px;
              font-size: 13px;
              margin-right: 15px;
              &.-insta {
                width: 39px;
                height: 39px;
                img {
                  width: 15px;
                  height: auto;
                }
              }
            }
          }
        }
      }
    }
  }
  @-webkit-keyframes imgmov {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-300%);
    }
  }
  @keyframes imgmov {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-300%);
    }
  }
  @-webkit-keyframes imgmov5 {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-400%);
    }
  }
  @keyframes imgmov5 {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-400%);
    }
  }

  //
  .roadmapBlank {
    height: 200px;
    @include sp {
      height: 100px;
    }
  }
  .secRoadmap {
    position: relative;

    @include pc {
      padding: 125px 0 0px;
      //margin-top: 80px;
    }
    @include sp {
      padding: 60px 0 0px;
    }

    &__head {
      //max-width: 1380px;
      margin: 0 auto 55px;
      &__ttl {
        @include mi-secTtl;
      }
      @include pc {
        max-width: 1260px;
        display: flex;
        justify-content: space-between;
        @include pcOver {
          max-width: pcpx(1260);
        }
        @include pcMax {
          max-width: pcpxMax(1260);
        }
      }
      @include sp {
        margin: 0 auto 25px;
        padding: 0 15px;
      }
    }
    &__bottom {
      @include pc {
        display: none;
      }
      @include sp {
        padding-top: 40px;
      }
    }

    .storyBtn {
      text-decoration: none;
      cursor: pointer;
      background: #fff;
      color: #000;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 50px;
      font-weight: 500;
      line-height: 1;

      padding: 0 25px 0 30px;
      font-size: 16px;
      width: 115px;
      height: 60px;
      &__icon {
        img {
          width: 12px;
          height: 12px;
        }
      }
      @include sp {
        height: 40px;
        width: 100px;
        font-size: 14px;
        padding: 0 15px 0 20px;
        margin: 0 auto;
        &__icon {
          img {
            width: 10px;
            height: 10px;
          }
        }
      }
    }

    .rmPhase {
      &:first-child {
        border-top: $line;
      }
      border-bottom: $line;
      &.-phase1 {
        .rmPhase__in {
          padding-bottom: 45px;
          @include sp {
            padding-bottom: 0px;
          }
        }
      }

      &__in {
      }
      &__txt1 {
        color: $color-red;
        line-height: 1;
        font-weight: 500;
        font-size: 16px;
        span {
          position: relative;
          display: inline-block;
          font-weight: 500;
          top: 6px;
          &:before {
            background: $color-red;
            content: '';
            position: absolute;
            left: 0;

            bottom: -12px;
            width: 8px;
            height: 2px;
          }
        }
      }
      &__txt2 {
        letter-spacing: 0.02em;
        line-height: calc(38 / 28);
        font-weight: 500;
        font-size: 28px;
      }
      &__txt3 {
        letter-spacing: 0.02em;
        line-height: calc(38 / 28);
        font-size: 28px;
        p {
          margin-bottom: 1em;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      @include pc {
        &__in {
          max-width: 1260px;
          padding: 70px 0;
          margin: 0 auto;
          display: flex;
          @include pcOver {
            max-width: pcpx(1260);
          }
          @include pcMax {
            max-width: pcpxMax(1260);
          }
        }
        &__txt1 {
          width: 175px;
        }
        &__txt2 {
          width: 235px;
        }
        &__txt3 {
          width: calc(100% - 175px - 235px);
        }
      }
      @include sp {
        padding: 30px 15px 25px;
        display: flex;
        &__in {
          //width: 48%;
          //margin-left: 7%;
          display: flex;
          flex-wrap: wrap;
        }
        &__txt1 {
          width: 100%;
          font-size: 12px;
          margin-bottom: 20px;
          span {
            top: 0px;
            &:before {
              bottom: -4px;
              height: 2px;
              width: 5px;
            }
          }
        }
        &__txt2 {
          line-height: calc(40 / 30);
          font-size: 15px;
          margin-bottom: 7px;
          width: 80px;
        }
        &__txt3 {
          line-height: calc(40 / 30);
          font-size: 15px;
          width: calc(100% - 80px);
          p {
            padding-bottom: 0.5em;
            border-bottom: $line;
            margin-bottom: 0.5em;
            &:last-child {
              padding-bottom: 0;
              margin-bottom: 0;
              border: none;
            }
          }
        }
      }

      .rmPhase1Block {
        letter-spacing: 0.02em;

        line-height: calc(38 / 28);
        font-size: 28px;

        @include pc {
          width: calc(100% - 125px - 80px);
        }

        .rmPhase1Top {
          display: flex;
          justify-content: space-between;
          border-bottom: $line;
          font-weight: 500;
          padding-bottom: 25px;
          margin-bottom: 20px;
          &__date {
            font-weight: 500;
            color: $color-red;
          }
          &:last-child {
            border-bottom: inherit;
          }
        }
        .rmPhase1Cnt {
          display: flex;
          &__head {
            font-weight: 500;
            padding-top: 30px;
            width: 235px;
          }
          &__body {
            width: calc(100% - 235px);
          }
        }
        @include sp {
          .rmPhase1Top {
            font-size: 15px;
            margin-bottom: 30px;
            padding-bottom: 12px;
          }
          .rmPhase1Cnt {
            display: flex;
            font-size: 15px;
            &__head {
              padding-top: 0px;
              width: 80px;
            }
            &__body {
              width: calc(100% - 80px);
            }
          }
        }
      } //.rmPhase1Block
    }
    .rmBox {
      &:last-child {
        border-bottom: none;
      }
      border-bottom: $line;
      &__in {
        line-height: calc(38 / 28);
        font-size: 28px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      }
      &__txt1 {
        // color: $color-red;
        // line-height: 1;
        // font-weight: 500;
        // font-size: 16px;

        // span{
        //   position: relative;
        //   display: inline-block;
        //   top: 6px;
        //   &:before{
        //     background: $color-red;
        //     content: '';
        //     position: absolute;
        //     left: 0;

        //     bottom: -12px;
        //     width: 8px;
        //     height: 2px;
        //   }
        // }
      }
      &__txt2 {
        font-weight: 500;
        color: $color-red;
        // line-height: (42 / 32);
        // font-size: 32px;
        &.-cs {
          font-size: 16px;
          @include sp {
            font-size: 10px;
            white-space: nowrap;
          }
        }
      }
      // &__txt3{
      //   line-height: (42 / 32);
      //   font-size: 32px;
      // }
      @include pc {
        &__imgSp {
          display: none;
        }
        &__in {
          max-width: 1260px;
          padding: 30px 0;
          margin: 0 auto;
          display: flex;
        }
        &__txt1 {
          //width: 175px;
          width: calc(100% - 145px);
        }
        &__txt2 {
          //width: 235px;
        }
        &__txt3 {
        }
      }
      @include sp {
        padding-bottom: 12px;
        margin-bottom: 25px;
        &:last-child {
          padding-bottom: 0;
          margin-bottom: 0;
          border-bottom: none;
        }
        &__imgSp {
          //width: 45%;
          line-height: 0;
          margin-bottom: 12px;
          img {
            height: 100%;
            &.-img1 {
              width: 33%;
            }
            &.-img2 {
              width: 100%;
            }
            &.-img3 {
              width: 50%;
            }
          }
        }
        &__in {
          width: 100%;
          //margin-left: 7%;
        }
        &__txt1 {
          line-height: calc(40 / 30);
          font-size: 15px;

          // margin-bottom: 17px;
          // span{
          //   top: 0px;
          //   &:before{
          //     bottom: -4px;
          //     height: 2px;
          //     width: 5px;
          //   }
          // }
        }
        &__txt2 {
          line-height: calc(40 / 30);
          font-size: 15px;
          //margin-bottom: 7px;
        }
        // &__txt3{
        //   line-height: (40 / 30);
        //   font-size: 15px;
        // }
      }
    } //

    @include pc {
      #stalker {
        pointer-events: none;
        position: fixed;
        //top: -8px;     //座標調節（カーソル位置と円の中心を合わせる）
        //left: -8px;    //座標調節（カーソル位置と円の中心を合わせる）
        //width: 16px;   //マウスストーカーの直径
        //height: 16px;  //マウスストーカーの直径
        background: rgba(248, 217, 47, 1);
        left: 0;
        top: 0;

        //border-radius: 50%;
        transition: transform 0.3s, top, 0.5s, left 0.5s, width 0.5s,
          height 0.5s, background-color 0.5s;
        transition-timing-function: ease-out;
        z-index: 99;
        // &.hov_{
        //   top: -32px;     //大きさに合わせて座標調節
        //   left: -32px;    //大きさに合わせて座標調節
        //   width: 64px;
        //   height: 64px;
        //   transition: .5s;
        //   background: rgba(248,217,47,0.5);
        // }
        &.-def {
          left: 50%;
          top: 50%;
        }
      }
      .stalkerImgBlock {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        //top:50%;
        // transform: translate(-50%,-50%);
        //left: 0;

        bottom: 0;

        //left: 20px;
        bottom: 20px;
        img {
          height: 240px;
          //   transform: scale(0);
          //   transition: transform .5s,width .5s;
          //   &.-in{
          //     transform: scale(1);
          //   }
        }
      }
    }
  }

  //-------------------------------------------------------------

  .secCommunity {
    color: #000;
    overflow: hidden;
    position: relative;
    &__bg {
      //background: url(../images/index/community-bg.jpg) center center /cover no-repeat;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      img {
        width: 100%;
      }
      @include sp {
        .simpleParallax {
          height: 100%;
        }
        @media all and (max-width: 680px) {
          img {
            width: auto;
            height: 100%;
          }
        }
      }
    }
    @include pc {
      height: 850px;
    }
    &__movTtl {
      //position: relative;

      position: absolute;
      top: 220px;
      &__in {
        color: $color-red;
        font-weight: 500;
        font-size: 150px;
        line-height: 1;
        -webkit-animation: textmov 40s linear infinite;
        animation: textmov 40s linear infinite;
        display: flex;
        span {
          padding-right: 0.3em;
          white-space: nowrap;
          display: block;
        }
      }
      @include pc {
        //width: 400vw;
      }
      @include sp {
        top: 85px;
        &__in {
          font-size: 100px;
        }
      }
    } //__movTtl

    &__in {
      position: relative;
      padding: 460px 0 155px;
      margin: 0 auto;
      max-width: 1100px;
      @include pc {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      }
      @include sp {
        padding: 210px 34px 110px;
      }
    }
    &__head {
      &__ttl {
        @include mi-secTtl(sml, #000);
      }
      &__txt {
        letter-spacing: 0.02em;
        line-height: calc(28 / 16);
        font-size: 16px;
      }
      @include pc {
        &__txt {
          max-width: 800px;
          margin-top: 32px;
        }
      }
      @include sp {
        margin-bottom: 30px;
        &__ttl {
          position: relative;
          margin-left: -20px;
        }
        &__txt {
          line-height: calc(38 / 26);
          font-size: 13px;
          margin-top: 1em;
        }
      }
    }
    &__cnt {
      @include sp {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .-discordBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #000;
        border-radius: 50px;
        text-decoration: none;
        font-weight: 500;
        color: #fff;

        width: 180px;
        height: 56px;
        font-size: 16px;
        &__txt {
          padding: 0 5px 0 0px;
          margin-left: 8px;
        }
        &__icon {
          display: inline-block;
          line-height: 0;
        }
        @include sp {
          width: 125px;
          height: 40px;
          font-size: 13px;
          img {
            position: relative;
            top: 2px;
            width: 15px;
          }
        }
      }
      &__list {
        display: flex;
        justify-content: center;
        line-height: 0;
        margin-top: 30px;
        li {
          margin: 0 9px;
          a {
            @include mi-imghvr;
          }
        }
        img {
          width: 29px;
        }
        @include sp {
          padding-left: 17px;
          margin-top: 0;
          li {
            margin: 0 5px;
          }
        }
      }
    }
  }

  //-------------------------------------------------------------
  .secTeam {
    //background: url(../images/common/bg.png) top center ;
    overflow: hidden;
    position: relative;
    border-bottom: $line;
    &__bg {
      line-height: 0;
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: center;
      img {
        width: 100%;
        max-width: 1400px;
      }
      @include sp {
        left: 50%;
        transform: translateX(-50%);
        width: 130%;
        max-width: 488px;
      }
    }
    &__in {
      position: relative;
      padding: 125px 0 280px;

      margin: 0 auto;
      @include sp {
        padding: 60px 0 125px;
      }
    }
    &__head {
      padding: 0 15px;
      &__ttl {
        @include mi-secTtl;
      }
      @include pc {
        margin-bottom: 45px;

        &__ttl {
          max-width: 1270px;
          margin: 0 auto;
          @include pcOver {
            max-width: pcpx(1270);
          }
          @include pcMax {
            max-width: pcpxMax(1270);
          }
        }
      }
      @include sp {
        margin-bottom: 30px;
      }
    }
    &__body {
      position: relative;
      @include pc {
        // display: flex;
        // flex-wrap: wrap;
        max-width: 1410px;
        margin: 0 auto;
        @include pcOver {
          max-width: pcpx(1410);
        }
        @include pcMax {
          max-width: pcpxMax(1410);
        }
        //padding: 0 15px;
      }
      @include sp {
        position: relative;
        overflow-x: auto; /* 横スクロールの指定 */
        white-space: nowrap; /* 横スクロールの指定 */
        overflow-scrolling: touch; /* スクロールを滑らかにする */
        -webkit-overflow-scrolling: touch; /* スクロールを滑らかにする */
        display: flex;
        padding: 0 0px 20px;

        &::-webkit-scrollbar {
          width: 5px;
          background: #eee;
        }
        &::-webkit-scrollbar:horizontal {
          height: 5px;
        }

        &::-webkit-scrollbar-thumb {
          background: #333;
        }
        &.-spOpen {
          overflow: hidden;
          padding-bottom: 25px;
        }
      }
    }

    .teamList {
      &__clm {
        display: flex;
      }
      @include pc {
        margin: 0 15px 30px;
        @include pcOver {
          margin: 0 pcpx(15) pcpx(30);
        }
        @include pcMax {
          margin: 0 pcpxMax(15) pcpxMax(30);
        }
        &__clm {
          justify-content: space-between;
          width: 100%;
          //position: relative;
          &:nth-child(1) {
            //z-index: 3;
          }
          &:nth-child(2) {
            // z-index: 2;
          }
        }
      }

      @include sp {
        position: relative;
        display: flex;
        margin-left: 15px;
      }
    }

    .teamBox {
      hyphens: auto;
      &__img {
        position: relative;
        overflow: hidden;
        @include pc {
          div {
            position: absolute;
            line-height: initial;
            width: 100%;
            bottom: 10px;
            right: 0;
            transform: scale(0.9);
          }
        }
        @include sp {
          div {
            position: absolute;
            line-height: initial;
            width: 100%;
            bottom: 10px;
            right: 0;
            transform: scale(0.5);
          }
        }
      }
      @include pc {
        display: flex;
        justify-content: space-between;
        width: calc(50% - 30px);
        margin-bottom: 70px;
        &__pcClose {
          opacity: 0;
          border-top: $line;
          margin-top: 20px;
          font-size: 12px;
          text-decoration: underline;
          padding-top: 15px;
          padding-bottom: 10px;
          a {
            cursor: pointer;
          }
        }
        &__txtareaPc {
          margin-top: 13px;
          line-height: calc(24 / 14);
          font-size: 14px;
        }
      }

      @include sp {
        width: 175px;
        margin-right: 30px;
        display: flex;
        flex-direction: column;
        &__txtBlockSP {
          &__spMore {
            border-top: $line;
            color: #fff;
            font-size: 12px;
            margin-top: 12px;
            padding-top: 12px;
            a {
              text-decoration: underline;
            }
          }
        }
        &__txtareaSp {
          overflow: hidden;
          white-space: initial;
          line-height: calc(36 / 24);
          font-size: 12px;
          height: 75px;
          margin-top: 7px;
          .jp {
            line-height: 1.6;
          }
        }
      }
      &__img {
        line-height: 0;
        img {
          border-radius: 15px;
          width: 100%;
        }
        @include pc {
          width: 42.425%;
        }
        @include sp {
          padding-bottom: 15px;
        }
      }

      &__txtBlock {
        //width: 51.516%;

        @include pc {
          width: 52.3%;
          padding-top: 15px;
          position: relative;
          &__pc {
            position: absolute;
            z-index: 1;
            overflow: hidden;
            transition: height 0.3s;
            &.-aH {
              height: auto;
            }
            &:before {
              content: '';
              background: rgba(15, 15, 15, 0.9);
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              opacity: 0;
              transition: opacity 0.3s 0.3s;
            }
            &.-open {
              &:before {
                opacity: 1;
                transition: opacity 0.3s 0s;
              }
              .teamBox__pcClose {
                opacity: 1;
              }
            }
          } //pc
          &__pcIn {
            position: relative;
          }

          &__pcMore {
            position: absolute;
            width: 100%;
            border-top: $line;
            font-size: 12px;
            top: 245px;
            padding-top: 15px;
            a {
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
      }
      //@

      &__txt1 {
        color: #aaaaaa;
        font-weight: 500;
        line-height: 1.5;
        font-size: 16px;
        @include sp {
          font-size: 12px;
        }
      }
      &__txt2 {
        font-weight: 600;
        letter-spacing: 0.02em;
        color: #fff;
        margin-top: 5px;
        font-size: 20px;
        @include sp {
          font-size: 17px;
          margin-top: 1px;
        }
      }
      .-year {
        margin-top: 5px;
        dl {
          display: flex;
          dt {
            width: 80px;
          }
          dd {
            width: calc(100% - 80px);
          }
        }
      }
      &__link {
        margin-top: 10px;
        &__icon {
          display: inline-block;
          margin-left: 8px;
        }
        a {
          color: inherit;
          font-size: 12px;
        }
      }
    } //-teamBox

    //pcOpen
    @include pc {
      &__body {
        .teamBox,
        .teamTxtList {
          transition: opacity 0.2s;
        }
        &.-pcOpen {
          .secTeam__body__pcOverlay {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
          }
          .teamBox,
          .teamTxtList {
            opacity: 0.4;
            pointer-events: none;
          }
          .teamBox.-openPc {
            opacity: 1;
            pointer-events: inherit;
            position: relative;
            z-index: 3;
          }
        }
      }
    }

    @include sp {
      .secTeam__body {
        .teamList,
        .teamTxtList {
          transform: translateX(100vw);
          transition: transform 1.2s $ease 0.3s;
        }
        &.-spView {
          .teamList,
          .teamTxtList {
            transform: translateX(0);
          }
        }
      }
    }

    .teamSpMdlWrp {
      display: none;
      background: rgba(15, 15, 15, 0.9);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      &__in {
        position: absolute;
        padding: 15px;
        width: calc(100vw - 30px);
        white-space: normal;
      }
      &__overlay {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
      }
      @include pc {
        display: none !important;
      }
    }
    .teamSpMdlCnt {
      @include sp {
        display: none;
        .teamBox__txt1 {
          color: #aaa;
        }
        &__txtarea {
          margin-top: 7px;
          .jp {
            line-height: 1.6;
          }
        }
        .-year {
          margin-top: 5px;
          dl {
            display: flex;
            dt {
              width: 80px;
            }
            dd {
              width: calc(100% - 80px);
            }
          }
        }
        .teamBox__link {
          font-size: 12px;
        }
        &__spClose {
          border-top: $line;
          margin-top: 20px;
          padding-top: 12px;
          font-size: 12px;
        }
      }
    }
    //------------------------------------

    .teamTxtList {
      @include pc {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: -5px;
      }
    }
    .teamTxt {
      font-weight: 500;
      text-align: left;
      border-bottom: $line;
      padding-bottom: 27px;
      &__ttl {
        color: #aaa;
        line-height: 1.5;
        font-size: 14px;
      }
      &__name {
        letter-spacing: 0.02em;

        line-height: calc(20 / 16);
        margin-top: 5px;
        font-size: 16px;
      }
      @include pc {
        width: calc(100% / 4 - 40px);
        margin: 0 15px;
      }
      @include sp {
        width: 155px;
        margin-left: 0px;
        margin-right: 0px;
        padding-bottom: 15px;
        margin-bottom: 28px;
        &:last-child {
          margin-bottom: 0;
        }
        &__ttl {
          font-size: 10px;
        }
        &__name {
          line-height: calc(40 / 30);
          margin-top: 0px;
          font-size: 15px;
        }
      }
    }

    &__bottomTxt {
      position: absolute;
      bottom: 40px;
      white-space: nowrap;
      -webkit-animation: textmov 40s linear infinite;
      animation: textmov 40s linear infinite;
      display: flex;
      p {
        font-weight: 500;
        white-space: nowrap;
        opacity: 0.12;
        color: $color-red;
        line-height: 1;
        font-size: 150px;
        padding-right: 0.5em;
      }
      @include sp {
        bottom: 20px;
        p {
          font-size: 90px;
        }
      }
    }
  }
  // -/.secTeam
  //-------------------------------------------------------------
  .secFaq {
    position: relative;
    background: #e5e5e5;
    color: $clr-grey2;
    &__bg {
      //background: url(../images/index/community-bg.jpg) center center /cover no-repeat;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      img {
        width: 100%;
      }
      @include sp {
        .simpleParallax {
          height: 100%;
        }
        @media all and (max-width: 680px) {
          img {
            width: auto;
            height: 100%;
          }
        }
      }
    }
    &__in {
      position: relative;
      padding: 195px 0 155px;
      max-width: 1100px;
      margin: 0 auto;
      @include pc {
        display: flex;
        justify-content: space-between;
      }
      @include pcOver {
        max-width: pcpx(1100);
      }
      @include pcMax {
        max-width: pcpxMax(1100);
      }
    }
    &__head {
      &__ttl {
        @include mi-secTtl(sml, $clr-grey2);
      }
    }
    &__body {
      width: 800px;
      @include pcOver {
        width: pcpx(800);
      }
      @include pcMax {
        width: pcpxMax(800);
      }
    }
    @include sp {
      &__in {
        padding: 95px 0 70px;
      }
      &__head {
        padding: 0 15px;
        margin-bottom: 30px;
      }
      &__body {
        width: auto;
      }
    }

    .faqBox {
      display: flex;
      border-bottom: #ccc solid 1px;
      font-size: 20px;
      padding-bottom: 30px;
      margin-bottom: 45px;
      &__num {
        font-weight: 500;
        width: 57px;
      }
      &__cnt {
        letter-spacing: 0.02em;
        width: calc(100% - 57px);
      }

      &__head {
        font-weight: 500;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        b {
          font-weight: bold;
        }
        &:after {
          content: '';
          transition: transform 0.2s, background-image 0.2s;
          background: url(../images/index/faq-icon.png) center center / cover
            no-repeat;
          width: 30px;
          height: 30px;
        }
        &.-open {
          &:after {
            transform: rotate(0deg) !important;
            background-image: url(../images/index/faq-icon-close.png);
          }
        }
        @include pc {
          &:hover {
            &:after {
              transform: rotate(90deg);
            }
          }
        }
      }
      &__body {
        display: none;
        color: $color-red;
        font-size: 18px;
        p {
          line-height: calc(40 / 28);
          padding-top: 15px;
          text-indent: -1.2em;
          padding-left: 1.2em;
        }
      }
      @include sp {
        font-size: 14px;
        margin-bottom: 30px;
        padding: 0 15px 20px 15px;
        &__num {
          width: 40px;
        }
        &__cnt {
          width: calc(100% - 40px);
        }

        &__head {
          font-size: 15px;
          &:after {
            width: 15px;
            height: 15px;
          }
          p {
            width: calc(100% - 15px);
          }
        }
        &__body {
          font-size: 14px;
          p {
            padding-top: 15px;
          }
        }
      }
    }
  }
  //-------------------------------------------------------------
  .remodal-overlay {
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: url(../images/index/roadmap-modal-bg.jpg) top center / 100% auto
      no-repeat #000;
    @include sp {
      background-image: url(../images/index/roadmap-modal-bg-sp.jpg);
    }
  }
  .rmMdlCnt {
    text-align: left;
    padding: 90px 0;
    position: relative;

    @include pc {
      max-width: 1100px;
    }
    &__subTtl {
      font-weight: 500;
      font-size: 35px;
    }
    &__ttl {
      font-weight: 500;
      line-height: calc(75 / 65);
      font-size: 65px;
      margin-top: 5px;
      margin-bottom: 85px;
    }
    &__txt {
      text-shadow: 0px 1px 5px rgba(0, 0, 0, 0.5);
      letter-spacing: 0.02em;
      line-height: calc(28 / 16);
      font-size: 16px;
    }
    @include sp {
      padding: 100px 5px;
      &__subTtl {
        font-size: calc(35px / 2);
      }
      &__ttl {
        //font-size: calc(65px / 2);
        font-size: 31px;
        margin-bottom: 50px;
        margin-top: 17px;
      }
      &__txt {
        line-height: calc(40 / 28);
        font-size: 14px;
      }
    }
    &__close {
      position: fixed;
      right: 65px;
      top: 90px;
      a {
        cursor: pointer;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #fff;
        color: #000;
        height: 60px;
        padding: 0 27px 0 30px;
        width: 83px;
        font-weight: 500;
        font-size: 16px;
      }

      &__icon {
        width: 12px;
        height: 12px;
      }
      @include sp {
        right: 30px;
        top: 15px;
        a {
          width: 65px;
          padding: 0 15px 0 17px;
          height: 40px;
          font-size: 14px;
        }
      }
    }
  }
}
@-webkit-keyframes textmov {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
@keyframes textmov {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
