#gallery {
  width: 100%;
  overflow: hidden;

  .header__nav a.-gallery {
    color: $color-red;
  }

  .galleryBg {
    width: 100%;
    top: 0;
    position: absolute;
    text-align: center;
    img {
      width: 100%;
      max-width: 1300px;
    }
    @include sp {
      display: none;
    }
  }

  .galleryCnt {
    position: relative;

    padding-top: 90px;
    padding-bottom: 150px;
    @include sp {
      padding-top: 50px;
      padding-bottom: 0;
    }
    &__head {
      overflow: hidden;
      line-height: 1;
      border-bottom: $line;
      height: 108px;

      &__txt {
        //font-weight: bold;
        position: relative;
        letter-spacing: -0.01em;
        font-size: 70px;
        font-weight: 500;
        display: flex;
        white-space: nowrap;
        //@at-root: textmov 40s linear infinite;
        //animation: textmov 40s linear infinite;
        &__in {
          position: absolute;
          left: 0;
          white-space: nowrap;
          -webkit-animation: textmov 40s linear infinite;
          animation: textmov 40s linear infinite;
        }
        p {
          display: inline-block;
          padding-right: 34px;
          &:before {
            content: '';
            background: #fff;
            border-radius: 50%;
            display: inline-block;
            position: relative;
            width: 36px;
            height: 36px;
            margin-right: 40px;

            top: -8px;
          }
        }
      }
      @include sp {
        height: 57px;
        &__txt {
          font-size: 38px;
          p {
            padding-right: 17px;
            &:before {
              height: 20px;
              width: 20px;
              margin-right: 17px;
              top: -5px;
            }
          }
        }
      }
    }
    &__body {
      max-width: 1380px;
      margin: 0 auto;
      @include pc {
        display: flex;
        justify-content: space-between;
        padding: 95px 30px 0;
      }
      @include pcOver {
        max-width: pcpx(1380);
        padding: 95px pcpx(30) 0;
      }
      @include pcMax {
        max-width: pcpxMax(1380);
        padding: 95px pcpxMax(30) 0;
      }
      @include sp {
        position: relative;
        padding-bottom: 77px;
      }
    }

    &__filter {
      &__head {
        line-height: 1;
        font-size: 30px;
        margin-bottom: 65px;
        &__spClose {
          @include pc {
            display: none;
          }
          @include sp {
            position: absolute;
            right: 15px;
            top: 10px;
            img {
              width: 17px;
            }
          }
        }
      }
      @include pc {
        width: 205px;
      }
      @include sp {
        display: none;
        position: absolute;
        left: 0;
        top: 0;
        //width: 100%;
        width: 330px;
        //background: rgba(0,0,0,.6);
        &__head {
          position: relative;
          background: #000;
          margin-bottom: 0;
          height: 70px;
          width: 330px;
          p {
            display: none;
          }
        }
        &__body {
          background: #000;
          width: 300px;
          padding: 0 15px;
        }
      }
    } //&__filter
    &__list {
      &__head {
        line-height: 1;
        display: flex;
        justify-content: space-between;
        margin-bottom: 50px;
        &__txt {
          font-size: 30px;
          .value {
            &__num {
              color: $color-red;
            }
          }
        }
        &__btn {
          a {
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(255, 255, 255, 0.3);
            border-radius: 50px;
            width: 180px;
            height: 56px;
          }
          @include pc {
            position: relative;
            margin-top: -13px;
          }
        }
        @include sp {
          margin-bottom: 22px;
          align-items: center;
          &__txt {
            font-size: 20px;
          }
          &__btn {
            a {
              width: 100px;
              height: 40px;
              img {
                height: auto;
                width: 65px;
              }
            }
          }
        }
      }
      &__body {
        display: flex;
        flex-wrap: wrap;
      }
      &__loading {
        text-align: center;
        color: #aaa;
        padding: 50px 0;
        font-size: 16px;
      }
      @include pc {
        width: calc(100% - 270px);
        .galleryItem {
          width: calc(25% - (90px / 4));
          width: 22.858%;
          margin-right: calc((100% - (22.858% * 4)) / 3);
          margin-bottom: 4.3%;
          &:nth-child(4n) {
            margin-right: 0;
          }
        }
      }
      @include sp {
        padding: 83px 15px 0;
        &__body {
          justify-content: space-between;
        }
        &__loading {
          padding: 22px 0;
          font-size: 13px;
        }
        .galleryItem {
          width: 47.83%;
          margin-bottom: 23px;
        }
      }
    }
    .spFilterOverlay {
      display: none;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.6);
      @include pc {
        display: none !important;
      }
    }
    .filterSpTtl {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
      font-size: 20px;
      &__txt {
        padding: 20px 0 0 15px;
        &__inTxt {
          margin-left: 8px;
        }
      }
      img {
        width: 13px;
      }
      .value {
        &__num {
          color: $color-red;
        }
      }
      @include pc {
        display: none;
      }
    }

    //filter
    .filterBox {
      border-bottom: $line;
      color: #aaa;
      font-size: 14px;
      padding: 18px 0;

      @include sp {
        font-size: 15px;
        padding-right: 3px;
      }
      &:first-child {
        border-top: $line;
      }

      &.-open {
        color: #fff;
        .filterBox__head {
          &__icon {
            &:before {
              background: #fff;
            }
            &:after {
              display: none;
            }
          }
        }
      }
      &__head {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__icon {
          position: relative;
          width: 10px;
          height: 10px;
          &:before,
          &:after {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
          &:before {
            content: '';
            background: #aaa;
            height: 2px;
            width: 10px;
          }
          &:after {
            content: '';
            background: #aaa;
            height: 10px;
            width: 2px;
          }
        }

        .value {
          padding-left: 6px;
          &__num {
            color: $color-red;
            padding-left: 6px;
          }
        }
        @include sp {
          &__icon {
            width: 15px;
            height: 15px;
            &:before {
              width: 15px;
            }
            &:after {
              height: 15px;
            }
          }
        }
      }
      &__body {
        display: none;
        padding-bottom: 10px;
        @include sp {
          padding-bottom: 0;
        }
      }
      &__list {
        padding-left: 10px;
        margin-top: 20px;

        li {
          //font-size: 14px;
          margin-bottom: 12px;
          &:last-child {
            margin-bottom: 0;
          }
        }

        input[type='checkbox'] {
          display: none;
        }
        input[type='checkbox']:checked + label {
          color: $color-red;
        }
        input[type='checkbox']:checked + label:before {
          border-color: $color-red;
          background-color: $color-red;
        }

        label {
          cursor: pointer;
          &:before {
            content: '';
            display: inline-block;
            border: solid 1px #fff;
            width: 8px;
            height: 8px;
            margin-right: 10px;
          }
        }
        @include pc {
          height: 170px;
          overflow-y: auto;
          &::-webkit-scrollbar {
            width: 5px;
            //background:#ee0;
          }

          &::-webkit-scrollbar-thumb {
            background: #aaa;
            border-radius: 10px;
            height: 10px;
          }
        }
        @include sp {
          padding-left: 10px;
          margin-top: 10px;
          li {
            margin-bottom: 6px;
          }
        }
      }
    }
    // -/filter

    .galleryItem {
      &__img {
        line-height: 0;
        img {
          width: 100%;
          border-radius: 10px;
        }
      }
      &__txt {
        color: #aaa;
        margin-top: 1em;
        font-size: 16px;
      }
      @include sp {
        &__txt {
          font-size: 13px;
        }
      }
    }
  }
}
@-webkit-keyframes textmov {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
@keyframes textmov {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
