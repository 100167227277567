// Content Width
// $max-width-md: 768px;
// $max-width-lg: 1160px;
// $max-width-xl: 1440px;
// $min-width-lg: 1030px;

// Fonts
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');

$font-base: 'neue-haas-unica', sans-serif;

// Color
$color-base: #000;
$color-white: #fff;
$color-red: #e50012;
$color-required: #ffe8e9;

$clr-grey: #999999;
$clr-grey2: #666;

$line: solid 1px rgba(255, 255, 255, 0.25);
// Z-index
// $modal: 500;
// $overlay: 400;
// $bottombar: 5;
// $topperbar: 200;
// $balloon: 3;
// $filterBrand: 1;
// $filterModel: 2;

$breakpoint: 1100px;
$ease: cubic-bezier(0.4, 0, 0, 1);
$bold: 600;
