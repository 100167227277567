#impacts {
  width: 100%;
  overflow: hidden;

  a.-impacts {
    color: $color-red;
  }

  @mixin mi-cntIn {
    margin-left: auto;
    margin-right: auto;
    @include pc {
      width: 1100px;
      @include pcOver {
        width: pcpx(1100);
      }
      @include pcMax {
        width: pcpxMax(1100);
      }
    }
  }

  .impactsBg {
    width: 100%;
    top: 0;
    position: absolute;
    text-align: center;
    img {
      width: 100%;
      max-width: 1300px;
    }
    @include sp {
      display: none;
    }
  }

  .impactsCnt {
    position: relative;

    padding-top: 90px;
    padding-bottom: 180px;
    @include sp {
      padding-top: 50px;
      padding-bottom: 0;
    }
    &__head {
      overflow: hidden;
      line-height: 1;
      border-bottom: $line;
      height: 108px;

      &__txt {
        //font-weight: bold;
        position: relative;
        letter-spacing: -0.01em;
        font-size: 70px;

        display: flex;
        white-space: nowrap;
        //@at-root: textmov 40s linear infinite;
        //animation: textmov 40s linear infinite;
        &__in {
          position: absolute;
          left: 0;
          white-space: nowrap;
          -webkit-animation: textmov 40s linear infinite;
          animation: textmov 40s linear infinite;
        }
        p {
          display: inline-block;
          font-weight: 500;
          padding-right: 34px;
          &:before {
            content: '';
            background: #fff;
            border-radius: 50%;
            display: inline-block;
            position: relative;
            width: 36px;
            height: 36px;
            margin-right: 40px;

            top: -8px;
          }
        }
      }
      @include sp {
        height: 57px;
        &__txt {
          font-size: 38px;
          p {
            padding-right: 17px;
            &:before {
              height: 20px;
              width: 20px;
              margin-right: 17px;
              top: -5px;
            }
          }
        }
      }
    }
    &__body {
      // max-width: 1380px;
      margin: 0 auto;
      @include pc {
        padding: 95px 0px 0;
      }
      // @include pcOver {
      //   max-width: pcpx(1380);
      //   padding: 95px pcpx(30) 0;
      // }
      // @include pcMax {
      //   max-width: pcpxMax(1380);
      //   padding: 95px pcpxMax(30) 0;
      // }
      @include sp {
        position: relative;
        padding-bottom: 100px;
      }
    }
  }
  .fvSec {
    @include mi-cntIn;
    margin-bottom: 137px;
    @include sp {
      margin-top: 25px;
      margin-bottom: 65px;
    }
    &__in {
      position: relative;

      @include pc {
        height: 690px;
        @include pcOver {
          height: pcpx(690);
        }
        @include pcMax {
          height: pcpxMax(690);
        }
      }
    }
    &__imgBlock {
      position: relative;
      width: 100%;
      height: 100%;
      @include sp {
        height: sppx(610);
        width: auto;
        margin-left: 15px;
        margin-right: 15px;
      }
    }
    &__img1,
    &__img2,
    &__img3 {
      overflow: hidden;
      border-radius: 10px;
      position: absolute;
    }
    &__img1 {
      right: 0;
      top: 0;
      @include pc {
        width: 630px;
        @include pcOver {
          width: pcpx(630);
        }
        @include pcMax {
          width: pcpxMax(630);
        }
      }
      @include sp {
        width: 78.264%;
      }
    }
    &__img2 {
      left: 0;
      @include pc {
        top: 140px;
        width: 420px;
        @include pcOver {
          top: pcpx(140);
          width: pcpx(420);
        }
        @include pcMax {
          top: pcpxMax(140);
          width: pcpxMax(420);
        }
      }
      @include sp {
        top: auto;
        bottom: 0;
        width: auto;
        height: sppx(228);
      }
    }
    &__img3 {
      @include pc {
        left: 220px;
        top: 470px;
        width: 280px;
        @include pcOver {
          left: pcpx(220);
          top: pcpx(470);
          width: pcpx(280);
        }
        @include pcMax {
          left: pcpxMax(220);
          top: pcpxMax(470);
          width: pcpxMax(280);
        }
      }
      @include sp {
        top: auto;
        bottom: 0;
        // width: 78.264%;
        width: auto;
        left: sppx(362);
        height: sppx(228);
      }
    }
    &__txt {
      letter-spacing: 0.12em;
      line-height: (38/18);
      font-size: 18px;
      @include pc {
        position: absolute;
        right: 0;
        top: 465px;
        width: 518px;
        @include pcOver {
          top: pcpx(465);
          width: pcpx(518);
        }
        @include pcMax {
          top: pcpxMax(465);
          width: pcpxMax(518);
        }
      }
      @include sp {
        margin: 20px 35px 0;
        line-height: (46/26);
        font-size: 13px;
      }
    }
  }

  .projectSec {
    letter-spacing: 0.12em;
    @include mi-cntIn();
    @include sp {
      margin-left: 15px;
      margin-right: 15px;
    }
    &__head {
      @include sp {
        margin-left: 20px;
        margin-right: 20px;
      }
      &__ttl {
        line-height: 1;
        letter-spacing: 0;
        color: $color-red;
        font-weight: 500;
        font-size: 45px;
        margin-bottom: 53px;
        @include sp {
          font-size: 25px;
          margin-bottom: 20px;
        }
      }
      &__txt1 {
        font-weight: $bold;
        font-size: 20px;
        @include sp {
          font-size: 15px;
        }
      }
      &__txt2 {
        font-weight: $bold;
        line-height: calc(60 / 40);
        margin-top: 10px;
        font-size: 32px;
        @include sp {
          font-size: 20px;
        }
      }
    }
  } //.projectSec
  .projectIntro {
    @include pc {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
      &__txt1,
      &__txt2 {
        font-size: 16px;
        width: 47.75%;
      }
    }
    @include sp {
      margin-left: 20px;
      margin-right: 20px;
      padding-top: 8px;
    }
    &__txt1 {
      line-height: calc(34 / 16);
      @include sp {
        line-height: (46/26);
        font-size: 13px;
      }
    }
    &__txt2 {
      letter-spacing: 0.12em;
      dt {
        font-weight: $bold;
        padding: 10px 0 20px;
        @include sp {
          font-size: 13px;
          padding: 23px 0 8px;
        }
      }
      dd {
        line-height: calc(30 / 16);
      }
    }
  } //.projectIntro

  .movieSec {
    @include pc {
      padding: 0 60px;
      margin-top: 57px;
    }
    @include sp {
      margin-left: 15px;
      margin-right: 15px;
      margin-top: 20px;
    }
    &__in {
      position: relative;
      width: 100%;
      padding-top: 56.25%;
      iframe {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  .imgSec {
    line-height: 0;
    margin-top: 150px;
    @include sp {
      margin-top: 70px;
    }
    &__list {
      width: 454px * 12;

      // &:nth-child(2) {
      //   img {
      //     transform: translateX(-50%);
      //   }
      // }
      -webkit-animation: imgsldmov 40s linear infinite;
      animation: imgsldmov 40s linear infinite;
      @include sp {
        width: 215px * 12;
      }
      &__in {
        white-space: nowrap;
      }
      &.-line2 {
        animation-direction: reverse;
        // .imgSec__list__in {
        //   transform: translateX(-50%);
        // }
      }
    }
    img {
      overflow: hidden;
      border-radius: 10px;
      width: 450px;
      margin-right: 2px;
      margin-left: 2px;
      margin-bottom: 4px;
      @include sp {
        width: 213px;
        margin-right: 1px;
        margin-left: 1px;
        margin-bottom: 2px;
      }
    }
  } //

  .actionSec {
    letter-spacing: 0.12em;
    font-weight: $bold;
    @include mi-cntIn();
    @include pc {
      margin-top: 137px;
    }
    @include sp {
      margin-top: 65px;
      margin-left: 35px;
      margin-right: 35px;
    }
    &__head {
      margin-bottom: 47px;
      @include sp {
        margin-bottom: 24px;
      }
      &__ttl {
        line-height: 1;
        letter-spacing: 0;
        color: $color-red;
        font-weight: 500;
        font-size: 45px;
        @include sp {
          font-size: 25px;
        }
      }
      &__txt {
        font-weight: $bold;
        line-height: calc(60 / 40);

        font-size: 32px;
        margin-top: 45px;
        @include sp {
          font-size: 20px;
          margin-top: 17px;
        }
      }
    }
    &__body {
      &__ttl {
        position: relative;

        padding-bottom: 20px;
        margin-bottom: 60px;

        font-size: 20px;
        @include sp {
          font-size: 15px;
          margin-bottom: 30px;
        }
        &:after,
        &:before {
          position: absolute;
          content: '';
          left: 0;
          bottom: 0;
          height: 1px;
        }
        &:after {
          background: #fff;
          width: 15px;
          @include sp {
            width: 7.5px;
          }
        }
        &:before {
          background: rgba(255, 255, 255, 0.25);
          width: 100%;
        }
      }
    }
    &__itemList {
      .item {
        @include pc {
          display: flex;
          align-items: center;
          margin-bottom: 60px;
        }
        @include sp {
          margin-bottom: 50px;
        }
        &:last-child {
          margin-bottom: 0;
        }
        &__clm {
          display: flex;
          @include pc {
            width: calc(100% - 310px);
            align-items: center;
          }
          @include sp {
            justify-content: space-between;
          }
        }
        &__img {
          line-height: 0;
          width: 200px;
          img {
            width: 100%;
          }
          @include pc {
            @include pcOver {
              width: pcpx(200);
            }
            @include pcMax {
              width: pcpxMax(200);
            }
          }
          @include sp {
            width: 39.34%;
          }
        }
        &__txt {
          font-weight: $bold;

          @include pc {
            margin-left: 60px;
          }
          @include sp {
            width: 54.4%;
          }
          dt {
            color: $color-red;
            font-size: 16px;
            margin-bottom: 25px;
            @include sp {
              line-height: (38/26);
              font-size: 13px;
              margin-bottom: 2px;
            }
          }
          dd {
            font-size: 24px;
            margin-bottom: 20px;
            @include sp {
              text-indent: -1em;
              padding-left: 1em;
              line-height: (48/30);
              margin-bottom: 6px;
              font-size: 15px;
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        &__btn {
          @include pc {
            width: 310px;
          }
          @include sp {
            margin-top: 20px;
          }
          a {
            display: flex;
            align-items: center;
            justify-content: center;
            background: #fff;
            color: #000;
            text-decoration: none;
            border-radius: 100px;
            width: 300px;
            height: 100px;
            @include pc {
              transition: background 0.2s, color 0.2s;
              .item__btn__intxt:after {
                transition: background 0.2s;
              }
              &:hover {
                background: $color-red;
                color: #fff;
                .item__btn__intxt:after {
                  background-image: url(../images/impacts/arw-w.png);
                }
              }
            }
            @include sp {
              width: calc(350px / 2);
              height: 55px;
              margin: 0 auto;
            }
          }
          .-csBtn {
            pointer-events: none;
            background: rgba(255, 255, 255, 0.65);
            width: 300px;
            height: 100px;
            &__intxt {
              text-align: center;
              color: #fff;
              line-height: (30/16);
              font-size: 16px;
            }
            @include sp {
              width: calc(350px / 2);
              height: 55px;
              margin: 0 auto;
              &__intxt {
                line-height: (38/24);
                font-size: 12px;
              }
            }
          }
          &__intxt {
            text-decoration: none;
            display: flex;
            justify-content: space-between;
            align-items: center;
            letter-spacing: 0.1em;
            line-height: (28/20);
            font-size: 20px;

            @include pc {
              width: 210px;
            }
            @include sp {
              line-height: (38/28);
              width: 130px;
              font-size: 14px;
              white-space: nowrap;
            }
            &:after {
              content: '';
              background: url(../images/impacts/arw.png) center center / cover
                no-repeat;
              display: inline-block;
              width: 18px;
              height: 12px;
              @include sp {
                width: 12.5px;
                height: 10px;
              }
            }
          }
        }
      }
    } //__itemList

    &__total {
      text-align: center;

      margin-top: 195px;
      &__txt1 {
        font-size: 40px;
      }
      &__txt2 {
        letter-spacing: 0.12em;
        margin-top: 10px;
        font-size: 25px;
      }
      &__val {
        line-height: 1;
        letter-spacing: 0;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 10px;
        margin: 40px auto 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;

        width: 800px;
        height: 150px;
        font-size: 60px;
      }
    }

    &__bottom {
      text-align: center;
      margin-top: 105px;
      @include sp {
        margin-top: 83px;
      }
      &__ttl {
        font-weight: 500;
        letter-spacing: 0;
        color: $color-red;
        font-size: 40px;
        @include sp {
          font-size: 22.5px;
        }
      }
      &__txt {
        letter-spacing: 0.12em;
        font-weight: normal;
        line-height: (36/16);
        margin-top: 12px;
        font-size: 16px;
        @include sp {
          margin-top: 8px;
          line-height: (46/26);
          font-size: 13px;
        }
      }
      &.-cs {
        margin-top: 160px;
        .actionSec__bottom__ttl {
          font-size: 60px;
          @include sp {
            font-size: 22.5px;
          }
        }
      }
    }
  } //.actionSec
} //#impacts
@-webkit-keyframes textmov {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
@keyframes textmov {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

@-webkit-keyframes imgsldmov {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
@keyframes imgsldmov {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
