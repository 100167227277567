body,
input,
textarea {
  font-family: $font-base;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
}
html {
  &.is-lock {
    overflow: hidden;
  }
}
body {
  color: #fff;

  &:before {
    content: '';
    z-index: -1;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: url(../images/common/bg.png) top center;
  }
  &.-fvPast {
    header {
      background: url(../images/common/bg.png) top left;
      &:before {
        opacity: 1;
      }
    }
  }
}

.header {
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  border-bottom: $line;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // &:before{
  //   position: absolute;
  //   content: '';
  //   left: 0;
  //   top:0;
  //   height: 100%;
  //   width: 100%;
  //   background: url(../images/common/bg.png) top left ;
  //   transition:opacity .1s;
  //   opacity: 0;
  // }
  transition: background 0.5s;
  .-cntW & {
    //border-bottom: solid 1px rgba(0,0,0,0.25);
  }
  &.-bg {
    background: url(../images/common/bg.png) top left;
    &:before {
      opacity: 1;
    }
  }
  &__logo {
    position: relative;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 145px;
    height: 60px;
    img {
      width: 110px;
    }
    @include sp {
      width: calc(194px / 2);
      height: calc(68px / 2);
      img {
        width: calc(157px / 2);
      }
    }
  }
  &__pcClm {
    @include pc {
      display: flex;
      align-items: center;
      height: 60px;
    }
  }
  &__nav {
    position: relative;
    padding-right: 25px;
    line-height: 1;
    font-size: 14px;
    a {
      text-decoration: none;
      color: #fff;
      .-cntW & {
        color: #000;
      }
    }

    ul {
      font-weight: 500;
      display: flex;
      // body[data-rmMdl="-closed"] &{
      //   padding-right: calc(100vw - 100%);
      // }
      li {
        margin-left: 24px;
      }
    }

    @include sp {
      display: none;
    }
  }
  &__lang {
    border-left: $line;
    height: 100%;
    font-weight: 500;
    padding: 0 30px;
    font-size: 12px;
    ul {
      height: 100%;
      display: flex;
      align-items: center;
      li {
        &:first-child {
          margin-right: 10px;
        }
      }
    }
    a {
      text-decoration: none;
      color: inherit;
      @include mi-imghvr;
      &.-cr {
        pointer-events: none;
        color: $color-red;
      }
      &.-disabled {
        color: $clr-grey2;
      }
    }
  }
  &__musicarea {
    border-left: $line;
    height: 100%;
    width: 174px;
  }
  &__spNav {
    @include pc {
      display: none;
    }
    position: relative;
    border-left: $line;
    padding-right: 15px;
    height: 34px;
    padding-left: 15px;
    a {
      display: block;
      border-top: solid 1px #fff;
      border-bottom: solid 1px #fff;
      width: 30px;
      height: 6px;
      margin-top: 13px;
    }
  }
}
//-/.header------------------------------------------------
.spNav {
  z-index: 101;
  display: none;
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  font-weight: 500;
  &__overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  &__in {
    position: relative;
    background: #000;
  }
  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;

    &__logo {
      background: #fff;
      height: 34px;
      width: 97px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 0;
      img {
        width: 78.5px;
      }
    }
    &__close {
      line-height: 0;
      margin-right: 20px;
      img {
        width: 17px;
      }
    }
  }

  @include mi-nav;
  &__nav {
    padding-bottom: 55px;
    &__clmRight {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    &__lang {
      display: flex;
      margin-top: 10px;
      a {
        text-decoration: none;
        font-weight: 500;
        font-size: 16px;
        margin-right: 20px;
        &.-cr {
          pointer-events: none;
          color: $color-red;
        }
        &.-disabled {
          color: $clr-grey2;
        }
      }
    }
  }
  &__bottom {
    border-top: $line;
    border-bottom: $line;
    &__btn {
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      text-decoration: none;
      color: inherit;
      padding: 25px 0;
      &__txt {
        font-size: calc(35px / 2);
      }
      &__arw {
        margin-left: 10px;
        img {
          width: 10px;
        }
      }
    }
    ul {
      display: flex;
      li {
        width: 50%;
        &.-os {
          border-right: $line;
          img {
            width: calc(217px / 2);
          }
        }
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          color: #fff;
          font-size: calc(35px / 2);
          height: 70px;
        }
      }
    }
  }
}
//-/.spNav------------------------------------------------

.footer {
  background: url(../images/common/bg.png) top center;
  position: relative;
  //border-top: $line;
  a {
    color: inherit;
  }
  &__cnt {
    &__head {
      display: flex;
      &__logoBlock {
        background: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        p {
          color: $clr-grey;
          line-height: 1;
          font-size: 14px;
          margin-top: 60px;
        }
      }
      &__txtBlock {
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: 0.02em;
        line-height: calc(28 / 16);
        font-size: 16px;
        p {
          hyphens: auto;
          width: 71%;
        }
      }
    }
    // &__body{
    // 	&__in{
    // 		display: flex;
    // 	}
    // }
    @include pc {
      display: flex;
      height: 310px;
      &__head {
        width: calc(100% - 470px);
        &__logoBlock {
          width: 300px;
          img {
            width: 151px;
          }
        }
        &__txtBlock {
          width: calc(100% - 300px);
        }
      }
      // &__body{
      // 	width: 469px;
      // 	border-left: $line;
      // 	display: flex;
      // 	align-items: center;
      // 	justify-content: center;
      // }
    }
    @include sp {
      &__head {
        border-bottom: $line;
        &__logoBlock {
          width: 125px;
          height: 125px;
          img {
            width: calc(151px / 2);
          }
        }
        &__txtBlock {
          width: calc(100% - 125px);
          line-height: calc(32 / 20);
          font-size: 10px;
          p {
            width: 88%;
          }
        }
      }

      // &__body{
      // 	padding: 35px 35px 35px 15px;
      // 	&__in{
      // 		justify-content: space-between;
      // 		//width: 87%;
      // 		max-width: 325px;
      // 		margin: 0 auto;
      // 	}
      // }
    }
    &__credit {
      color: $clr-grey;
      line-height: 1.5;
      padding: 0 20px 37px;
      font-size: 10px;
      @include sp {
        padding: 0 15px 37px 15px;
        p {
          max-width: 350px;
          margin: 0 auto;
        }
      }
    }
  }

  @include mi-nav;

  &__bottom {
    border-top: $line;
    display: flex;
    justify-content: space-between;
    line-height: 1;
    color: $clr-grey;

    padding: 25px 40px;
    font-size: 14px;
    @include sp {
      padding: 20px 16px;
      font-size: 12px;
    }
    &__clm {
      @include pc {
        display: flex;
        &.-left {
          width: calc(100% - 430px);
        }
        &.-right {
          width: 430px;
          justify-content: space-between;
        }
      }
    }
    &__credit {
      line-height: calc(20 / 14);
      font-size: 14px;
      @include pc {
        width: calc(100% - 260px);
        p {
          margin: 0 auto;
          width: 71%;
        }
      }
    }
    &__link {
      //display: flex;
      li {
        //margin-right: 23px;
      }
      @include pc {
        margin-left: 80px;
        li {
          margin-bottom: 10px;
        }
        a {
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
    &__backtotop {
      a {
        text-decoration: none;
        position: relative;
        display: inline-block;
        // &:before {
        //   content: '';
        //   background: $clr-grey;
        //   position: absolute;
        //   top: 50%;
        //   transform: translateY(-50%);
        //   border-radius: 50%;
        //   left: -16px;
        //   width: 6px;
        //   height: 6px;
        // }
      }
      &__dot {
        position: absolute;
        // left: -11px;
        // top: -20px;
        left: -16px;
        bottom: 0;

        width: 6px;
        height: 6px;
        &:before {
          position: absolute;
          content: '';
          background: $clr-grey;
          border-radius: 50%;
          width: 100%;
          height: 100%;
          -webkit-animation: dotmovUp 1.5s $ease infinite;
          animation: dotmovUp 1.5s $ease infinite;
        }
      }
      @-webkit-keyframes dotmovUp {
        0% {
          transform: translateY(150%);
          opacity: 0;
        }
        50% {
          opacity: 1;
        }
        70% {
          opacity: 0.9;
        }
        100% {
          transform: translateY(-150%);
          opacity: 0;
        }
      }
      @keyframes dotmovUp {
        0% {
          transform: translateY(150%);
          opacity: 0;
        }
        50% {
          opacity: 1;
        }
        80% {
          opacity: 0.9;
        }
        100% {
          transform: translateY(-150%);
          opacity: 0;
        }
      }
    }
    &__copy {
      color: $clr-grey;
      @include pc {
        width: 260px;
      }
    }
  } //bottom
}
//-/.footer------------------------------------------------
audio {
  display: none;
}
.audioBlock {
  cursor: pointer;
  position: absolute;

  line-height: 1;
  display: flex;
  @include pc {
    z-index: 101;
    position: fixed;
    right: 130px;
    top: 23px;
  }
  @include sp {
    right: 75px;
    top: 11px;
  }

  &__txtBlock {
    position: relative;
    overflow: hidden;
    font-weight: 500;
    width: 70px;
    height: 17px;
    font-size: 14px;
    @include sp {
      width: 65px;
      height: 13px;
      font-size: 12px;
    }
    .audioTtl {
      display: none;
      &:first-child {
        display: block;
      }
    }
    .-textMov {
      &__in {
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        -webkit-animation: textmov 20s linear infinite;
        animation: textmov 20s linear infinite;
      }
      span {
        padding-right: 0.5em;
        display: block;
        white-space: nowrap;
      }
    }
  }
  &__icon {
    padding-left: 15px;
    margin-left: 15px;
    border-left: dotted 1px #fff;
    &:before {
      content: '';
      background: url(../images/common/music-icon-play.png) center center /
        cover no-repeat;
      display: inline-block;
      width: 14px;
      height: 14px;
    }
    @include pc {
      &:before {
        transition: opacity 0.3s;
      }
      &:hover {
        &:before {
          opacity: 0.6;
        }
      }
    }
    @include sp {
      margin-left: 10px;
      padding-left: 10px;
      &:before {
        width: 10px;
        height: 10px;
      }
    }
  }
  &.-play {
    .audioBlock__icon:before {
      background-image: url(../images/common/music-icon-pause.png);
    }
  }
}

//マウスオーバー
.-txtHvrMov {
  @include pc {
    &__bg {
      transition: background 0.4s $ease;
      &:hover {
        background: #e60012 !important;
      }
    }

    &:hover {
      .-txtHvrMov__base,
      .-txtHvrMov__ov {
        transform: translateY(-100%);
      }
    }
    &__wrp {
      display: inline-block;
      position: relative;
      overflow: hidden;
    }
    &__base {
      display: inline-block;
      transition: transform 0.4s $ease;
    }
    &__ov {
      position: absolute;
      left: 0;
      top: 100%;
      transition: transform 0.4s $ease;
    }
  }
  @include sp {
    &__ov {
      display: none;
    }
  }
}

.ftBreadcrumb {
  position: relative;
  border-top: $line;
  border-bottom: $line;
  line-height: 1;
  padding: 30px;
  font-size: 14px;
  ul {
    display: flex;
    a {
      text-decoration: none;
      color: #a2a2a2;
    }
  }
  li {
    &:after {
      background: url(../images/common/ft-bc-arw.png) center center / cover
        no-repeat;
      content: '';
      display: inline-block;
      width: 6px;
      height: 10px;
      margin: 0 20px;
    }
    &:last-child {
      color: $color-red;
      &:after {
        display: none;
      }
    }
  }
  @include sp {
    padding: 17px 15px;
    font-size: 10px;
    li {
      &:after {
        width: 5px;
        height: 8px;
        margin: 0 8px;
      }
    }
  }
}

// loading styles by hasegawa
.loading {
  z-index: 2000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000 url(../images/common/bg.png) top center;
  transition-property: opacity, visibility;
  transition-duration: 0.2s;
  transition-timing-function: linear;

  &[data-is-loaded='true'] {
    opacity: 0;
    visibility: hidden;
  }
}

.loading__inner {
  position: absolute;
  left: 25px;
  font-weight: 500;
  color: $color-white;
  font-size: 16px;
  top: 50%;
  transform: translateY(-50%);

  @include pc {
    font-size: 20px;
    left: 90px;
  }
}

.loading__logo {
  position: relative;
  width: 115px;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 50 / 231 * 100%;
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    fill: #fff;
  }

  @include pc {
    width: 190px;
  }
}

.loading__text {
  margin: 16px 0 6px;

  @include pc {
    margin: 24px 0 8px;
  }
}

.loading__percent {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
  height: 1em;
}

.loading__digit {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.loading__digitItem {
  height: 1em;
  width: 0.6em;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bgRedCircle {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
}

.jp {
  display: block;
}
.en {
  display: none;
}
