#event {
  a.-event {
    color: $color-red;
  }
}
.container.eventShibuya {
  width: 100%;
  overflow: hidden;
  .-red {
    color: $color-red;
  }
  a.-event {
    color: $color-red;
  }

  @mixin mi-cntIn {
    margin-left: auto;
    margin-right: auto;
    @include pc {
      width: 1100px;
      @include pcOver {
        width: pcpx(1100);
      }
      @include pcMax {
        width: pcpxMax(1100);
      }
    }
  }

  .eventBg {
    width: 100%;
    top: 0;
    position: absolute;
    text-align: center;
    img {
      width: 100%;
      max-width: 1300px;
    }
    @include sp {
      display: none;
    }
  }

  .eventCnt {
    position: relative;

    padding-top: 90px;
    padding-bottom: 205px;
    &__in {
      @include mi-cntIn();
    }
    @include sp {
      padding-top: 34px;
      padding-bottom: 120px;
      &__in {
        margin-left: 35px;
        margin-right: 35px;
      }
    }
  }

  .fvSec {
    background: url(../images/event/shibuya/kv-pc.jpg) center center / cover
      no-repeat;
    @include pc {
      height: 615px;
      border-radius: 10px;
      margin: 0 30px 70px;
    }
    @include sp {
      background-image: url(../images/event/shibuya/kv-sp.jpg);
      height: 500px;
      margin-bottom: 35px;
    }
    &__in {
      @include mi-cntIn();
      padding-top: 80px;
      @include sp {
        margin: 0 30px;
        padding-top: 55px;
      }
    }
    &__ttl {
      font-weight: 500;
      line-height: (90/88);
      letter-spacing: -0.02em;
      font-size: 90px;
      @include sp {
        line-height: 1;
        font-size: 55px;
      }
    }
    &__txt {
      font-weight: 600;
      letter-spacing: 0.1em;
      margin-top: 20px;
      font-size: 25px;
      @include sp {
        margin-top: 22px;
        font-size: 15px;
      }
    }
    &__btn {
      margin-top: 80px;
      @include sp {
        margin-top: 150px;
      }
      a {
        font-weight: 600;
        border-radius: 30px;
        position: relative;
        text-decoration: none;
        background: rgba(230, 0, 18, 0.7);
        display: inline-block;
        line-height: 1;
        color: #fff;
        font-size: 16px;
        width: 200px;
        padding: 22px 0 22px 50px;
        &:after {
          position: absolute;

          top: 50%;
          transform: translateY(-50%);

          content: '';
          background: url(../images/event/shibuya/arw.png) center center / cover
            no-repeat;
          display: inline-block;
          width: 12px;
          height: 10px;
          right: 24px;
          @include sp {
            right: 17px;
            width: 12.5px;
            height: 10px;
          }
        }
        @include pc {
          transition: background 0.2s, color 0.2s;
          &:after {
            transition: background 0.2s;
          }
          &:hover {
            color: $color-red;
            background: #fff;
            &:after {
              background-image: url(../images/event/shibuya/arw-red.png);
            }
          }
        }
        @include sp {
          width: 160px;
          padding: 17px 0 17px 40px;
          font-size: 10px;
          margin-left: auto;
          margin-right: 25px;
          display: block;
          font-size: 14px;
        }
      }
    }
  }

  .introSec {
    @include pc {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
    &__txtarea {
    }
    &__nav {
      @include sp {
        display: none;
      }
      padding-bottom: 5px;
      font-size: 14px;

      a {
        font-weight: $bold;
        text-decoration: none;
        color: inherit;
        transition: color 0.2s;
        &:hover {
          color: $color-red;
        }
      }
      li {
        margin-top: 20px;
      }
    }
    &__ttl {
      color: $color-red;
      font-weight: 500;
      font-size: 45px;
      @include sp {
        letter-spacing: -0.01em;
        font-size: 25px;
      }
    }
    &__txt1 {
      font-weight: $bold;
      line-height: 2;
      letter-spacing: 0.12em;
      font-size: 24px;
      margin-top: 45px;
      @include sp {
        margin-top: 25px;
        font-size: 13px;
      }
    }
    &__txt2 {
      letter-spacing: 0.12em;
      line-height: 2;
      margin-top: 40px;
      font-size: 16px;
      @include sp {
        font-size: 11px;
        margin-top: 25px;
      }
    }
  }
  //.introSec
  @mixin mi-secTtl {
    &__ttl {
      position: relative;
      line-height: 1;
      font-weight: $bold;
      letter-spacing: 0.12em;
      font-size: 20px;
      border-bottom: $line;
      padding-bottom: 20px;
      margin-bottom: 45px;
      @include sp {
        font-size: 15px;
        padding-bottom: 11px;
        margin-bottom: 25px;
      }
      &:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: -1px;
        background: #fff;
        height: 1px;
        width: 15px;
        @include sp {
          width: 7.5px;
        }
      }
    }
  }
  .aboutSec {
    padding-top: 115px;
    @include mi-secTtl;
    @include sp {
      padding-top: 60px;
    }
    &__cnt {
      dl {
        line-height: 2;
        letter-spacing: 0.12em;
        font-size: 16px;
        margin-bottom: 20px;
        @include pc {
          display: flex;
        }
        @include sp {
          margin-bottom: 25px;
        }
        dt {
          font-weight: $bold;
          @include pc {
            width: 140px;
          }
          @include sp {
            font-size: 13px;
          }
        }
        dd {
          @include sp {
            letter-spacing: 0.05em;
            font-size: 12px;
          }
        }
      }
    }
    &__btn {
      margin-top: 55px;
      a {
        border-radius: 40px;
        background: rgba(230, 0, 18, 0.7);
        display: flex;
        justify-content: center;
        align-items: center;
        color: inherit;
        letter-spacing: 0.1em;
        margin: 0 auto;
        text-decoration: none;
        font-weight: $bold;
        width: 250px;
        height: 60px;
        font-size: 16px;
        @include sp {
          width: 224px;
          height: 57.5px;
          font-size: 15px;
        }
        @include pc {
          transition: background 0.2s, color 0.2s;
          &:hover {
            color: $color-red;
            background: #fff;
          }
        }
      }
    }
  }
  // -/.aboutSec
  .benefitsSec {
    padding-top: 80px;
    @include mi-secTtl;
    &__cnt {
      dl {
        letter-spacing: 0.12em;
        font-size: 16px;
        margin-bottom: 30px;

        dt {
          font-weight: $bold;
          margin-bottom: 20px;
          &.-red {
            color: $color-red;
          }
        }
        dd {
          line-height: (40/16);
        }
        @include pc {
          dd {
            padding-left: 1.5em;
          }
        }
        @include sp {
          font-size: 13px;
          margin-bottom: 37px;
          dt {
            line-height: (42/26);
            margin-bottom: 15px;
          }
          dd {
            line-height: (48/26);
            margin-bottom: 10px;
            &.-note {
              font-size: 11px;
            }
          }
        }
      }
    }
    &__note {
      font-weight: $bold;
      letter-spacing: 0.12em;
      margin-top: 40px;
      font-size: 16px;
      @include sp {
        margin-top: 30px;
        line-height: (38/22);
        font-size: 11px;
      }
      a {
        // color: $color-red;
        color: inherit;
      }
    }

    &__itemList {
      margin-top: 60px;

      @include pc {
        display: flex;
        justify-content: space-between;
        li {
          width: 30.91%;
        }
      }
      @include sp {
        margin-top: 40px;
      }
      li {
      }
    }
    .item {
      @include sp {
        margin-bottom: 50px;
      }
      &__img {
        line-height: 0;
        margin-bottom: 35px;
        img {
          position: relative;
          overflow: hidden;
          width: 100%;
          border-radius: 10px;
        }
        @include sp {
          margin-bottom: 20px;
        }
      }
      &__ttl {
        letter-spacing: 0.12em;
        font-weight: $bold;
        margin-bottom: 15px;
        font-size: 16px;
        @include sp {
          font-size: 14px;
        }
      }
      &__txt {
        letter-spacing: 0.12em;
        line-height: (24/14);
        font-size: 14px;
        @include sp {
          letter-spacing: 0.1em;
          line-height: (40/23);
          font-size: 11.5px;
        }
      }
    }
  }
  // .benefitsSec
  .whatsSec {
    border-top: $line;
    margin-top: 110px;
    padding-top: 50px;
    @include sp {
      padding-top: 40px;
      margin-top: 50px;
      position: relative;
      left: -35px;
      width: calc(100% + 70px);
      &__in {
        padding-left: 35px;
        padding-right: 35px;
      }
    }
    &__ttl {
      font-weight: 500;
      color: $color-red;
      margin-bottom: 10px;
      font-size: 45px;
      @include sp {
        line-height: (62/50);
        font-size: 25px;
      }
    }
    &__subTtl {
      font-weight: $bold;
      margin-bottom: 35px;
      font-size: 24px;
      @include sp {
        font-size: 14px;
        margin-bottom: 20px;
      }
    }
    &__txt {
      letter-spacing: 0.12em;
      line-height: (34/16);
      font-size: 16px;
      @include sp {
        line-height: 2;
        font-size: 11.5px;
      }
      .-note {
        font-size: 11px;
        margin-top: 15px;
        @include sp {
          font-size: 10px;
        }
      }
    }
    &__btn {
      margin-top: 60px;
      @include sp {
        margin-top: 43px;
      }
      a {
        border-radius: 40px;
        background: rgba(255, 255, 255, 0.2);
        display: flex;
        justify-content: center;
        align-items: center;
        color: inherit;
        text-decoration: none;
        font-weight: 500;
        width: 170px;
        height: 56px;
        font-size: 16px;
        @include pc {
          transition: background 0.2s;
          &:hover {
            background: $color-red;
          }
        }

        @include sp {
          font-size: 13px;
          width: 130px;
          height: 40px;
        }
      }
    }
  }
  // .whatsSec
} //#event
