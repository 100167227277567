//header sp /footer spナビ
@mixin mi-nav {
  &__nav {
    font-weight: 500;
    a {
      color: inherit;
    }
    &__in {
      display: flex;
    }
    @include pc {
      width: 469px;
      border-left: $line;
      display: flex;
      align-items: center;
      justify-content: center;
      &__in {
        padding-top: 10px;
      }
    }
    @include sp {
      padding: 35px 15px 35px 15px;
      &__in {
        justify-content: space-between;
        //width: 87%;
        max-width: 350px;
        margin: 0 auto;
      }
    }

    .linkList,
    .linkListSns {
      a {
        text-decoration: none;
      }
      dt {
        color: $clr-grey;
        line-height: 1;
        margin-bottom: 20px;
        font-size: 14px;
        @include sp {
          font-size: 12px;
          margin-bottom: 20px;
        }
      }
      dd {
        color: #fff;
        line-height: 1;
        &:last-child {
          margin-bottom: 0;
        }
        a {
          line-height: 1;
          display: inline-block;
          display: flex;
          align-items: center;
        }
      }
    }
    .linkList {
      dt {
      }
      dd {
        font-size: 20px;
        margin-bottom: 10px;
      }
      @include pc {
        margin-right: 110px;
      }
      @include sp {
        dd {
          font-size: 22px;
          margin-bottom: 8px;
          .-red {
          }
          &.-home {
            margin-top: -5px;
            color: $color-red;
          }
        }
      }
    }
    .linkListSns {
      dt {
      }
      dd {
        display: flex;
        align-items: center;

        font-size: 14px;
        margin-bottom: 14px;
        a {
          @include mi-imghvr;
        }
        .-icon {
          position: relative;
          top: 1px;
          margin-right: 13px;
          img {
            width: 20px;
          }
        }
        @include sp {
          font-size: 16px;
          margin-bottom: 13px;
          .-icon {
            margin-right: 10px;
            img {
              width: 18px;
            }
          }
        }
      }
    }
    .linkListPolicy {
      padding-top: 32px;
      font-size: 12px;
      li {
        line-height: 1;
        margin-bottom: 10px;
        a {
          color: $clr-grey;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  } //__nav
}

@mixin mi-secTtl($type: ttl, $clr: #fff) {
  line-height: 1;
  span {
    position: relative;
    display: inline-block;
    font-weight: 500;
    font-size: 70px;
    &:before {
      position: absolute;
      content: '';
      background: $clr;
      left: -36px;
      bottom: 5px;

      width: 22px;
      height: 4px;
    }
    @include sp {
      font-size: calc(65px / 2);
      padding-left: 20px;
      &:before {
        left: 0;
        width: 12px;
        height: 2px;
      }
    }
  }

  @if $type == sml {
    span {
      font-size: 30px;
      &:before {
        bottom: 5px;
        left: -22px;
        width: 12px;
        height: 2px;
      }
      @include sp {
        font-size: calc(65px / 2);
        padding-left: 18px;
        &:before {
          left: 0px;
          width: 11px;
          height: 2px;
        }
      }
    }
  }
}
